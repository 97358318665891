<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          v-model="listQuery.operationId"
          placeholder="请选择代理商"
          :maxLength="50"
          clearable
        >
          <el-option
            v-for="(item, i) in agentList"
            :value="item.id"
            :label="item.operationName"
            :key="i"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        label="代理商名称"
        prop="operationName"
      ></el-table-column>

      <el-table-column label="进账天数" prop="inBoundDays" align="right"></el-table-column>

      <el-table-column label="出账天数" prop="outBoundDays" align="right"></el-table-column>

      <el-table-column label="进账金额" prop="inBoundMoney" align="right"></el-table-column>

      <el-table-column label="出账金额" prop="outBoundMoney" align="right"></el-table-column>
      
      <el-table-column label="操作" prop="operation">
        <template #default="scope">
           <span class="option option-primary" @click="goAssets(scope.row)">资产明细</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { agentAsset  } from "@/api/agent";
export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList;
    },
  }),
  data() {
    return {
      dataSource: [],
      listQuery: {
      
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {

    // 代理商资产详情
    goAssets(data){
      this.$router.push(`/agent/asset-detail/${data.operationId}/${data.phone}`);
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      agentAsset({ 
        operationId : this.id,
        phone: this.phone,
        ...this.pages
       }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    let params = this.$router.currentRoute.value.params;
    this.id = params.id;
    this.phone = params.phone;

    this.$store.dispatch("fetchAgentListAll").then((res) => {
      this.getList();
    });
  },
};
</script>
